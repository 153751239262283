.header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 7%; 
    z-index: 100;
    position: fixed;
    top: 0;
    width: calc(100% - 320px);
    left: 0;

    @include breakPoint(medium) {
        width: 100%;
    }

    @include themify($themes) {
        background-color: themed('headerBackgroundColor');  
        min-height: themed('headerHeight');
    }

    .logo {

        font-weight: bold;
        @include themify($themes) {
            color: themed('headerFontColor');  
        }
        

        svg {
            @include themify($themes) {
                font-size: themed('headerLogoHeight');  
            }
        }
    }
}

.landing-page-wrapper,
.thank-you-page-wrapper {
    .header-section {
        max-height: 60px;
        margin: 40px 20px;
        padding: 0 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 100;

        @include breakPoint(medium) {
            max-height: 50px;
            margin: 30px;
            padding: 0;

            .logo {
                width: 125px;
            }
        }

        .menu-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include breakPoint(menucollapse2) {
                display: none;
            }
            .drop-selector {
                margin: 0 25px;
                border-top: 3px solid transparent;
                border-bottom: 3px solid #ffffff;

                @include breakPoint(medium) {
                    margin: 0 15px;
                }
            } 

            .menu-link {
                @include themify($themes) {
                    font-family: themed('fontFamilyHeadings');
                }
                font-weight: 800;
                font-size: 17px;
                line-height: 57px;
                color: #142037;
                margin: 0 25px;
                border-top: 3px solid transparent;
                border-bottom: 3px solid #ffffff;

                @include breakPoint(menucollapse) {
					margin: 0 15px;
					font-size: 15px;
				}

                &:hover {
                    @include themify($themes) {
                        border-bottom: 3px solid themed('primaryColor');
                    }
                    -webkit-transition: border-color 500ms ease;
                    -ms-transition: border-color 500ms ease;
                    transition: border-color 500ms ease;
                }
            }

            .cta {
                margin-left: 25px;

                @include breakPoint(medium) {
                    margin-left: 15px;
                }
            }
        }

        .burger-icon-wrapper {
            background: #F2F5FA;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            justify-content: center;
            align-items: center;
            display: none;
            z-index: 100;
        
            @include breakPoint(menucollapse2) {
                display: flex;
            } 
        }
    }
}