.thank-you-page-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-section {
        padding: 70px;
        margin: 0;
        background: #EEF2FA;

        @include breakPoint(medium) {
            padding: 55px 30px;
        }

        .menu-links {
            .menu-link {
                border-bottom: 3px solid transparent;
            }
        }
    }

    .footer-section {
        z-index: 5;
    }

    .thank-you-wrapper {
        padding: 20px 30px 0;
        position: relative;
        overflow: hidden;

        .dots-bottom {
            position: absolute;
            bottom: -210px;
            right: -30px;
            z-index: 0;
            transform: rotate(90deg);
            @include breakPoint(medium) {
               display: none;
            }
        }

        .cta-wrapper {
            height: 230px;
            background: #EEF2FA;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            padding-bottom: 70px;

            a {
                @include themify($themes) {
                    font-family: themed('fontFamilyHeadings');
                }
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #8593AC;

                &:hover {
                    @include themify($themes) {
                        color: themed('primaryFontColor');
                        transition: color 500ms ease;
                    }
                }
            }

            .cta {
                text-transform: none;
            }
        }

        .figure {
            max-width: 320px;
            width: 100%;
        }
    }
}