.confirmation-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    max-width: 660px;

    h2 {
        margin: 30px 0;
        @include themify($themes) {
            color: themed('trinaryColor');  
        }
    }

    button {
        margin-top: 50px;
    }

    .selv-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #EEF2FA;
        border-radius: 16px;
        padding: 0 20px 10px;
        margin-bottom: 40px;
        max-width: 393px;
        width: 100%;
        min-height: 234px;
        background: #FFFFFF;
        border-radius: 16px;
        z-index: 2;

        img {
            width: 143px;
        }
    }
}