.great-success, .thank-you-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    padding-top: 100px;
    background: #EEF2FA;
    position: relative;

    .figure {
        max-width: 275px;
    }

    button {
        text-transform: uppercase;
    }

    .cta-wrapper {
        height: 125px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .great-success-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 900px;
        margin: 40px 0;

        .great-success-content {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 40px 0;

            .figure-wrapper, .great-success-text-wrapper {
                width: 50%;
                max-width: 450px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 2;
            }

            @include breakPoint(small) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 30px;
                display: flex;

                .figure-wrapper, .great-success-text-wrapper {
                    margin: 20px 0;
                    width: 100%;
                }
             }

            .figure-wrapper {
                align-items: center;
            }

            .great-success-text-wrapper {
                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    img {
                        width: 21px;
                    }

                    h3 {
                        margin-left: 18px;
                        font-weight: bold;
                    }
                }

                p {
                    margin: 15px 0 0 40px;
                    max-width: 415px;
                    font-weight: 500;
                }
            }
        }
    }
}

@include breakPoint(small) {
    #middle-item {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);

        div {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
    }
}

.great-success, .thank-you-page-wrapper {
    .dots-top {
        position: absolute;
        top: -300px;
        left: 130px;
        z-index: 1;
        transform: rotate(270deg);
    }
}