.footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 7%; 

    @include themify($themes) {
        background-color: themed('footerBackgroundColor');  
        min-height: themed('footerHeight');
    }

    .logo {

        @include themify($themes) {
            text-align: themed('footerLogoAlign');  
        }
        
        @include themify($themes) {
            color: themed('footerFontColor');  
        }
        

        svg {
            @include themify($themes) {
                font-size: themed('footerLogoHeight');  
            }
        }
    }

    &.cookie-bar-bottom-bar {
        bottom: 70px;
    }
}

.landing-page-wrapper,
.thank-you-page-wrapper {
    .footer-section {
        background: #151F35;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 100px 30px 20px;
        width: 100%;

        a, h1, p {
            color: #FFFFFF;
        }

        p {
            margin: 10px 0;
            max-width: 580px;
            text-align: center;
        }

        h1 {
            margin: 70px 0 20px;
        }

        .footer-links {
            margin-top: 60px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        @include breakPoint(small) {
            .line-break {
                display: none;
            }

            .footer-links {
                flex-direction: column;
                height: 90px;
                margin: 130px 0 30px;
            }
        }
    }
}