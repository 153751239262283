@mixin font-size($size) {
    font-size: $size;
  
    // sass-lint:disable no-duplicate-properties
    font-size: #{$size / 16px}rem;
}

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}
